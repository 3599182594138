// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../HeroBackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".advisoryBoard {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n  position: relative;\r\n  z-index: 0;\r\n}\r\n.advisoryBoard::after {\r\n  position: absolute;\r\n  content: \"\";\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: #001123;\r\n  opacity: 0.7;\r\n  z-index: -1;\r\n}", "",{"version":3,"sources":["webpack://./src/assets/css/sass/about/advisoryboard.scss","webpack://./src/assets/css/sass/about/advisoryboard.css"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;ACCJ;ADCI;EACI,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;ACCR","sourcesContent":[".advisoryBoard{\r\n    background-image: url(../../../HeroBackground.png);\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    position: relative;\r\n    z-index: 0;\r\n\r\n    &::after{\r\n        position: absolute;\r\n        content: \"\";\r\n        top: 0;\r\n        left: 0;\r\n        width: 100%;\r\n        height: 100%;\r\n        background-color: #001123;\r\n        opacity: 0.7;\r\n        z-index: -1;\r\n    }\r\n}\r\n.team-title{\r\n    color: $primaryColor;\r\n}",".advisoryBoard {\r\n  background-image: url(../../../HeroBackground.png);\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n  position: relative;\r\n  z-index: 0;\r\n}\r\n.advisoryBoard::after {\r\n  position: absolute;\r\n  content: \"\";\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: #001123;\r\n  opacity: 0.7;\r\n  z-index: -1;\r\n}/*# sourceMappingURL=advisoryboard.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
