export const testimonials = [
    {
        id: 1,
        img: 'https://media.istockphoto.com/photos/pensive-mature-man-looking-at-the-camera-on-gray-background-picture-id1334905996?k=20&m=1334905996&s=612x612&w=0&h=yzzgyBbSI7q4UdB3GkyNFoGyBb7Kw1Sm7cu5NaT0d1o=',
        name: 'John Doe',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
    },
    {
        id: 2,
        img: 'https://media.istockphoto.com/photos/pensive-mature-man-looking-at-the-camera-on-gray-background-picture-id1334905996?k=20&m=1334905996&s=612x612&w=0&h=yzzgyBbSI7q4UdB3GkyNFoGyBb7Kw1Sm7cu5NaT0d1o=',
        name: 'John Doe',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
    },
    {
        id: 3,
        img: 'https://media.istockphoto.com/photos/pensive-mature-man-looking-at-the-camera-on-gray-background-picture-id1334905996?k=20&m=1334905996&s=612x612&w=0&h=yzzgyBbSI7q4UdB3GkyNFoGyBb7Kw1Sm7cu5NaT0d1o=',
        name: 'John Doe',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
    },
    {
        id: 4,
        img: 'https://media.istockphoto.com/photos/pensive-mature-man-looking-at-the-camera-on-gray-background-picture-id1334905996?k=20&m=1334905996&s=612x612&w=0&h=yzzgyBbSI7q4UdB3GkyNFoGyBb7Kw1Sm7cu5NaT0d1o=',
        name: 'John Doe',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
    },
]