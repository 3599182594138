export const questionOne = [
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
]

export const questionTwo = [
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
    'We are learning about how AI can help us reach our business objectives',
]